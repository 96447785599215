import React from "react";
import CustomLink from "../custom_link";

type LinkProps = {
  to: string;
  children: React.ReactNode;
};

const IconLink = ({ to, children }: LinkProps) => (
  <CustomLink
    className={`group  cursor-pointer leading-none  text-dsc-theme-body duration-150
      [&_svg]:h-8 [&_svg]:w-8 [&_svg]:rounded-full [&_svg]:bg-dsc-theme-accent [&_svg]:p-2 [&_svg]:text-white [&_svg]:hover:scale-125
    `}
    to={to}
  >
    <div className="flex items-center justify-center gap-2"> {children}</div>
  </CustomLink>
);
export default IconLink;
