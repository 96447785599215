import { graphql, useStaticQuery } from "gatsby";
import droneImage from "../../images/dorone_dxable_og.png";
import React from "react";
type SEOProps = {
  title: string;
  description: string;
  path: string;
  image?: string;
};

const SEO = ({ title, description, path, image }: SEOProps) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  const socialImage = image ?? (data.site.siteMetadata.siteUrl + droneImage);
  const url = data.site.siteMetadata.siteUrl + path;
  return (
    <>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="Drone,drone,Drone.io,ドローン,DevOps,CI,継続的インテグレーション,Drone代理店,Drone.io代理店"
      />
      <link rel="canonical" href={path} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={socialImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={socialImage} />
    </>
  );
};

export default SEO;
