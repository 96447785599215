import React from "react";

export type IFeatureCard = {
  image: {
    src: string;
    alt: string;
  };
  title: string;
  body: string;
};

function HomeFeatureCard({ image, title, body }: IFeatureCard) {
  return (
    <div className="flex flex-col items-center gap-y-4 ">
      <img
        className="max-h-[100px]"
        title={image.alt}
        width={100}
        height={100}
        {...image}
      />
      <p className="text-center font-bold text-2xl">{title}</p>
      <p>{body}</p>
    </div>
  );
}

export default HomeFeatureCard;
