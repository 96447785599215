import { StaticImage } from "gatsby-plugin-image";
import React from "react";

function HomeCode() {
  return (
    <section className="grid  grid-cols-1 items-center gap-x-14 gap-y-8 md:grid-cols-2 ">
      <div className="order-first text-dsc-theme-body md:order-last">
        <h2 className="dsc-h2 mb-6 font-bold  text-dsc-theme-title">
          プラグインの作成と共有
        </h2>
        <p className="mb-6 ">
          Droneはコンテナを使用して、事前設定されたステップをパイプラインにドロップします。何百もの既存のプラグインから選択することもできますし、独自のプラグインを作成することもできます。
        </p>
      </div>
      <div className="flex justify-center">
        <StaticImage
          src="../../../images/home/code.png"
          alt="DroneBanner"
          width={450}
          quality={100}
          placeholder="tracedSVG"
        />
      </div>
    </section>
  );
}
export default HomeCode;
