import React from "react";
import SectionSpaceSmall from "../../common/section-space-small";

import HomeFeatureCard, { IFeatureCard } from "./home-feature-card";

type HomeFeatureProps = {
  header?: {
    title: string;
    body: string;
  };
  items: IFeatureCard[];
};

function HomeFeature({ items, header }: HomeFeatureProps) {
  return (
    <>
      {header && (
        <>
          <div className="text-center">
            <h2 className="dsc-h2 mb-6">{header.title}</h2>
            <p>{header.body}</p>
          </div>
          <SectionSpaceSmall />
        </>
      )}
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-3">
        {items.map((item) => (
          <HomeFeatureCard key={item.title} {...item} />
        ))}
      </div>
    </>
  );
}
export default HomeFeature;
