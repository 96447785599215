// import { StaticImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import Button from "../../common/button";
function HomeBanner() {
  return (
    // <Button to={"/"}>お問い合わせ</Button>
    <section className="relative overflow-hidden py-8 px-4 md:h-[500px] md:px-8 ">
      <div className="absolute inset-0 z-0 h-full w-full">
        <StaticImage
          className="w-full"
          src="../../../images/home/banner-background.png"
          alt="Drone Banner Image"
          title="Drone Banner Image"
          layout="fullWidth"
          height={450}
        />
      </div>
      <div className="relative z-10 grid  h-full grid-cols-1 items-center justify-center gap-x-14 gap-y-8 md:grid-cols-2">
        <div className="flex-1 text-dsc-theme-body">
          <h1 className="dsc-h1-sm mb-6 font-bold  text-dsc-theme-title">
            ソフトウェアのビルドとテストを自動化する
          </h1>
          <p className="mb-6 text-lg">
            Droneは多忙な開発チーム向けの、セルフサービス方式の継続的インテグレーションプラットフォームです。
          </p>
          <div className="flex flex-wrap justify-center gap-3 md:justify-start">
            <Button to={"/お問い合わせ/"}>お問い合わせ</Button>
          </div>
        </div>
        <div className="flex justify-center">
          <StaticImage
            src="../../../images/home/drone-banner.png"
            alt="DroneBanner"
            width={500}
            quality={100}
            placeholder="tracedSVG"
          />
        </div>
      </div>
    </section>
  );
}
export default HomeBanner;
