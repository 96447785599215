import * as React from "react";
import SectionSpace from "../components/common/section-space";
import HomeBanner from "../components/home/home-banner";
import HomeCD from "../components/home/home-cd";
import HomeFeature from "../components/home/home-feature";
import { IFeatureCard } from "../components/home/home-feature/home-feature-card";
import Layout from "../layout/";

import sourceImage from "../images/home/source.svg";
import languageImage from "../images/home/language.svg";
import platformImage from "../images/home/platform.svg";
import simpleImage from "../images/home/simple.svg";
import isolatedImage from "../images/home/isolated.svg";
import effortlessImage from "../images/home/effortless.svg";
import type { HeadProps } from "gatsby";
import HomeCode from "../components/home/home-code";
import SEO from "../components/seo";
import HomeArticles from "../components/home/home-articles";

export const feature1: IFeatureCard[] = [
  {
    image: {
      src: sourceImage,
      alt: "Source Image",
    },
    title: "主要なソースコードマネージャーに対応",
    body: "Droneは、GitHub、GitHub Enterprise、Bitbucket、GitLabなどの複数のソースコード管理システムとシームレスに統合できます。",
  },
  {
    image: {
      src: platformImage,
      alt: "Platform Image",
    },
    title: "任意のプラットフォームに対応",
    body: "Linux x64、ARM、ARM64、Windows x64などの複数のオペレーティングシステムとアーキテクチャをネイティブにサポートしています。",
  },
  {
    image: {
      src: languageImage,
      alt: "Language Image",
    },
    title: "どんな言語でも利用できます",
    body: "Dockerコンテナ内で実行できる任意の言語、データベース、サービスで動作します。公開された何千ものDockerイメージから選択できるだけでなく、独自のイメージも使えます。",
  },
];

export const feature2: IFeatureCard[] = [
  {
    image: {
      src: simpleImage,
      alt: "Simple Image",
    },
    title: "シンプルな構成",
    body: "ビルドサーバの構成に時間をかけないで済みます。既に用意されている何千種類ものDockerイメージから選択すると、Droneが環境を自動プロビジョニングします。",
  },
  {
    image: {
      src: isolatedImage,
      alt: "Isolated Image",
    },
    title: "各ビルドは完全にアイソレート可能",
    body: "共有サーバでのビルドの競合について心配する必要はありません。すべてのビルドは隔離されたDockerコンテナで実行されるため、完全に制御できます。",
  },
  {
    image: {
      src: effortlessImage,
      alt: "Effortless Image",
    },
    title: "スケーリングもシンプルに",
    body: "シンプルさとスケーラビリティを同時に実現できます。Droneは単一のバイナリからインストールされ、自動的にスケーリングされます。",
  },
];

const IndexPage = () => {
  return (
    <Layout>
      <main>
        <HomeBanner />
        {/* <SectionSpace /> */}
        <div className="px-5 md:px-8">
          <HomeCD />
          <SectionSpace />
          <HomeFeature items={feature1} />
          <SectionSpace />
          <HomeCode />
          <SectionSpace />
          <HomeFeature
            header={{
              title: "高度なカスタマイズが可能",
              body: "Droneは高度なカスタマイズを容易にします。カスタムアクセス制御、承認ワークフロー、シークレット管理、YAML構文拡張などを実装します。",
            }}
            items={feature2}
          />
          <SectionSpace />
          <HomeArticles />
          <SectionSpace />
        </div>
      </main>
    </Layout>
  );
};

export const Head = ({ location }) => {
  return (
    <SEO
      title={
        "Drone（Drone.io）｜セルフサービス型継続的インテグレーション（CI）プラットフォーム｜Drone正規販売代理店 DXable"
      }
      description={
        "Drone（Drone.io）は、ビルド・テスト・リリースのワークフロー自動化のためのオープンソースの継続的インテグレーション（CI）プラットフォームです。"
      }
      path={location.pathname}
    />
  );
};

export default IndexPage;
