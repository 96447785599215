import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import CustomLink from "../../common/button/custom_link";
import IconLink from "../../common/button/icon_link";
// import { Link } from "gatsby";
const HomeArticles = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiBlog(limit: 4, sort: { fields: published_date, order: DESC }) {
        nodes {
          title
          slug
          published_date(locale: "ja", formatString: "ll")
          seo {
            description
          }
        }
      }
    }
  `);
  console.log("Articles", { data });
  return (
    <section className="flex flex-col items-center justify-center font-normal">
      <h2 className="text-center text-2xl font-bold uppercase leading-relaxed md:text-2xl">
        DRONE の最新情報
      </h2>
      <p className="text-dsc-theme-muted-500 my-4 w-full text-center font-normal">
        Drone関連のお知らせ・コンテンツ更新情報
      </p>
      <div className="flex cursor-pointer flex-col gap-2 rounded-md border-gray-200 p-4 md:w-fit md:max-w-[max(700px,80%)] md:py-0">
        {data?.allStrapiBlog.nodes.map((blog) => (
          <div className="flex flex-col gap-4 border-b border-b-gray-200 py-4 md:flex-row md:justify-start md:gap-6 md:py-6">
            <div className="flex w-full items-center justify-start gap-4 md:contents">
              <p className="min-w-[130px]">
                {blog.published_date}
                {/* {moment(itm.releasedate).format("ll")} */}
              </p>
              <button
                className={`h-[36px]  min-w-[158px] overflow-hidden rounded-full bg-dsc-theme-accent bg-opacity-10 px-4 text-sm text-dsc-theme-accent  `}
              >
                Drone 関連
                {/* {checkDx(itm.link)} */}
              </button>
            </div>
            <CustomLink to={`/news/${blog.slug}/`}>
              <div className="contents min-w-[288px] gap-1 md:flex">
                <p className="text-left line-clamp-2 ">{blog.title}</p>
                {/* {false && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline w-6 h-6 md:block shrink-0"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>
                )} */}
              </div>
            </CustomLink>
          </div>
        ))}
      </div>
      <div className="mx-auto my-4 flex flex-col text-center md:flex-row md:text-left">
        <IconLink to="/news/">
          <strong className="font-bold">News一覧をみる</strong>
          <ArrowRightIcon />
        </IconLink>

        {/* <CustomLink
              className=" px-4 flex items-center gap-2 min-w-[169px] h-[36px] text-sm overflow-hidden rounded-full bg-hn-primary text-hn-primary bg-opacity-10"
              to="/news/"
            >
              ブログ一覧を見る
              <img
                className="object-contain w-6 aspect-square"
                src={"/icons/arrow.svg"}
                alt="more"
              />
            </CustomLink>
            <CustomLink
              className=" px-4 flex items-center gap-2 min-w-[169px] h-[36px] text-sm overflow-hidden rounded-full bg-hn-primary text-hn-primary bg-opacity-10"
              to="/news/"
            >
              ブログ一覧を見る
              <img
                className="object-contain w-6 aspect-square"
                src={"/icons/arrow.svg"}
                alt="more"
              />
            </CustomLink> */}
      </div>
    </section>
  );
};

export default HomeArticles;
