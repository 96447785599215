import { StaticImage } from "gatsby-plugin-image";
import React from "react";

function HomeCD() {
  return (
    <section className="grid grid-cols-1 items-center gap-x-14 gap-y-8 md:grid-cols-2">
      <div className="flex-1 text-dsc-theme-body">
        <h2 className="dsc-h2 mb-6 font-bold  text-dsc-theme-title">
          設定をコード化 <br />
          (Configuration as a code)
        </h2>
        <p className="mb-6 ">
          パイプラインは、Gitリポジトリにコミットするシンプルで可読性の高いファイルで構成されます。
        </p>
        <h3>
          パイプラインの各ステップは、実行時に自動的にダウンロードされる隔離されたDockerコンテナ内で実行されます。
        </h3>
      </div>
      <div className="flex justify-center">
        <StaticImage
          src="../../../images/home/cd.png"
          alt="DroneBanner"
          width={450}
          quality={100}
          placeholder="tracedSVG"
        />
      </div>
    </section>
  );
}
export default HomeCD;
